import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import awsExports from "../src/aws-exports";
import "../styles/globals.css";
import type { AppProps } from 'next/app'
import {Theme} from "@aws-amplify/ui";
import {ThemeProvider} from "@aws-amplify/ui-react";

Amplify.configure({ ...awsExports, ssr: true });
const theme: Theme = {
  name: 'button-theme',
  tokens: {
    colors: {
      border: {
        // this will affect the default button's border color
        primary: { value: 'black' },
      },
    },
    components: {
      button: {
        // this will affect the font weight of all button variants
        fontWeight: { value: '{fontWeights.extrabold}' },
        // style the primary variation
        primary: {
          backgroundColor: { value: "#ffc93c" },
          color:  { value: "{colors.black}" },
          _hover: {
            backgroundColor: { value: "#ffc93c" },
            color:  { value: "hsl(43 100% 32%)" },
          },
          _focus: {
            backgroundColor: { value: "#ffc93c" },
            color:  { value: "hsl(50 80% 40%)" },
          },
          _active: {
            backgroundColor: { value: "#ffc93c" },
            color:  { value: "{colors.white}" },
          },
        },
      },
    },
  },
};

function MyApp({ Component, pageProps }: AppProps) {
  return <ThemeProvider theme={theme} colorMode="light"><Component {...pageProps} /></ThemeProvider>;
}

export default MyApp;
